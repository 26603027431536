import React from "react"
import { Link } from "gatsby"
import {
  TitleMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "components/typography/typography"
import { PolyglotBackground, PolyglotMedia } from "posts/polyglot/banner/banner"
import styles from "./card.module.css"

export const Card = ({ title, type, date, slug }) => {
  const dateNew = new Date(date)
  const dateFormatted = dateNew.toLocaleDateString("en-au", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Link to={slug} className={styles.card}>
      <TitleMedium className={styles.title}>{title}</TitleMedium>
      <ParagraphSmall className={styles.meta}>
        {dateFormatted} · {type}
      </ParagraphSmall>
    </Link>
  )
}

export const CustomCard = ({
  title,
  type,
  date,
  caption,
  link,
  linkAction,
}) => {
  const dateNew = new Date(date)
  const dateFormatted = dateNew.toLocaleDateString("en-au", {
    year: "numeric",
  })

  return (
    <a href={link} className={styles.customCard}>
      <PolyglotBackground />

      <div className={styles.content}>
        <TitleMedium className={styles.customCardTitle}>{title}</TitleMedium>
        <ParagraphSmall className={styles.customCardMeta}>
          {dateFormatted} · {type}
        </ParagraphSmall>
        <ParagraphMedium className={styles.customCardCaption}>
          {caption}
        </ParagraphMedium>
        <span className={styles.button}>{linkAction}</span>
      </div>

      <div className={styles.media}>
        <PolyglotMedia />
      </div>
    </a>
  )
}
