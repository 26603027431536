import React from "react"
import styles from "./grid.module.css"
import * as classNames from "classnames"

export const Grid = ({ className, children }) => {
  const gridClasses = classNames(styles.grid, className)

  return <div className={gridClasses}>{children}</div>
}

export const GridItem = ({ className, children }) => {
  const gridItemClasses = classNames(styles.gridItem, className)

  return <div className={gridItemClasses}>{children}</div>
}
