import React from "react"
import styles from "./banner.module.css"

export const PolyglotBackground = () => {
  return (
    <div className={styles.background}>
      <div className={styles.waves}>
        <div className={styles.wave} />
        <div className={styles.wave} />
      </div>
    </div>
  )
}

export const PolyglotMedia = () => {
  return (
    <div className={styles.translationsContainer}>
      {translations.map(translation => {
        return (
          <PolyglotListItem
            key={translation.language}
            countryCode={translation.countryCode}
            text={translation.text}
            chars={translation.chars}
            diff={translation.diff}
          />
        )
      })}
    </div>
  )
}

const PolyglotListItem = ({ countryCode, text, chars, diff }) => {
  return (
    <div className={styles.translationContainer}>
      <div class={styles.translationLanguage}>
        <img src={require(`./assets/flag-${countryCode}.png`)} />
      </div>
      <div className={styles.translationText}>{text}</div>
      <ul class={styles.translationLength}>
        <li>{chars}</li>
        <li>{diff}</li>
      </ul>
    </div>
  )
}

const translations = [
  {
    language: "French",
    countryCode: "fr",
    text: "Salut tout le monde",
    chars: "19 characters",
    diff: "73% increase",
  },
  {
    language: "Greek",
    countryCode: "el",
    text: "Γεια σου κόσμε",
    chars: "14 characters",
    diff: "27% increase",
  },
  {
    language: "Bulgarian",
    countryCode: "bg",
    text: "Здравей, свят",
    chars: "13 characters",
    diff: "18% increase",
  },
  {
    language: "English",
    countryCode: "en",
    text: "Hello world",
    chars: "11 characters",
    diff: "Original language",
  },
  {
    language: "Russian",
    countryCode: "ru",
    text: "Всем привет",
    chars: "11 characters",
    diff: "Same length",
  },
  {
    language: "Spanish",
    countryCode: "es",
    text: "Hola mundo",
    chars: "10 characters",
    diff: "9% decrease",
  },
  {
    language: "German",
    countryCode: "de",
    text: "Hallo welt",
    chars: "10 characters",
    diff: "9% decrease",
  },
]
