import React from "react"
import AllPostsQuery from "queries/allPostsQuery"
import Layout from "components/layout/layout"
import Meta from "components/meta/meta"
import { Grid, GridItem } from "components/grid/grid"
import { Card, CustomCard } from "components/card/card"

const Index = () => (
  <Layout>
    <Meta />
    <AllPostsQuery
      render={data =>
        data.allMdx.edges.map(edge =>
          edge.node.frontmatter.customCard ? (
            <CustomCard
              slug={edge.node.fields.slug}
              {...edge.node.frontmatter}
            />
          ) : (
            <Card slug={edge.node.fields.slug} {...edge.node.frontmatter} />
          )
        )
      }
    />
  </Layout>
)

export default Index
