import React from "react"
import styles from "./typography.module.css"
import * as classNames from "classnames"

export const TitleLarge = ({ children, className }) => {
  const allClassNames = classNames(styles.titleLarge, className)
  return <h2 className={allClassNames}>{children}</h2>
}

export const TitleMedium = ({ children, className }) => {
  const allClassNames = classNames(styles.titleMedium, className)
  return <h3 className={allClassNames}>{children}</h3>
}

export const ParagraphMedium = ({ children, className }) => {
  const allClassNames = classNames(styles.medium, className)
  return <p className={allClassNames}>{children}</p>
}

export const ParagraphSmall = ({ children, className }) => {
  const allClassNames = classNames(styles.small, className)
  return <p className={allClassNames}>{children}</p>
}
